import { domain } from "@/globalVariables";
import httpClient from "@/api/client/httpClient";

export default {
  data() {
    return {};
  },
  methods: {
    createLinkPool() {
      httpClient({
        url: `/AttachmentFile/create`,
        method: "POST",
        data: [
          {
            type: "link",
            label: this.links[i].name,
            link: this.links[i].url
          }
        ]
      })
        .then((response) => {
          this.validLinks.push(response.data.data.id);
          if (this.links.length - countContinue - countError === this.validLinks.length) {
            this.createPage();
          }
        })
        .catch((error) => {
          countError++;
          this.errorAlert(error);
          if (this.links.length - 1 === parseInt(i)) {
            this.createPage();
          }
        });
    }
  }
};
