import axios from "axios";
import { domain } from "@/globalVariables";
import httpClient from "@/api/client/httpClient";

export default {
  data() {
    return {
      pullNameCover: "",
      chunksArrCover: [],
      coverUploader: [],
      category: "covers"
    };
  },
  methods: {
    getBase64Cover(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    createPullCover(arr) {
      if (!arr.length) {
        this.filesUploadedCover();
        return null;
      }
      httpClient({
        url: `${domain}/monolit/File/createChunkPool`,
        method: "POST",
        data: [
          {
            file_name: arr[0].name
          }
        ]
      })
        .then((response) => {
          this.pullNameCover = response.data.data.chunk_pool_name;
          this.createChunksCover(arr);
        })
        .catch((error) => {
          this.loading = false;
          this.errorAlert(error);
        });
    },
    async createChunksCover(arr) {
      await this.getBase64Cover(arr[0].file).then((resp) => {
        let base64 = resp.replace(resp.substring(0, resp.search(",") + 1), "");
        if (arr[0].size < 50000000) {
          this.chunksArrCover = base64.match(/.{1,500000}/g);
        } else {
          this.chunksArrCover = base64.match(/.{1,5000000}/g);
        }
        // TODO: Если файлы большие
        // this.chunksArr = base64.match(/.{1,5000000}/g)
      });
      let countError = 0;
      for (const [i, item] of this.chunksArrCover.entries()) {
        if (countError) {
          this.loading = false;
          this.$root.$emit("showNotification", {
            type: "error",
            timeout: 5000,
            label: `Возникли какие-то проблемы с загрузкой файлов, попробуйте загрузить еще раз!`
          });
          return null;
        }
        await httpClient({
          url: `${domain}/monolit/File/uploadPoolChunk`,
          method: "POST",
          data: [
            {
              pool_name: this.pullNameCover,
              base_64_chunk: item,
              category: this.category
            }
          ]
        })
          .then(() => {
            if (i === this.chunksArrCover.length - 1) {
              this.getFileFromPoolCover(arr);
            }
          })
          .catch((error) => {
            countError++;
            this.errorAlert(error);
          });
      }
    },
    getFileFromPoolCover(arr) {
      httpClient({
        url: `${domain}/monolit/File/collectFileFromPool`,
        method: "POST",
        data: [this.pullNameCover, this.category]
      })
        .then((response) => {
          this.cover = response.data.data.path;
          arr.splice(0, 1);
          this.createPullCover(arr);
        })
        .catch((error) => {
          this.errorAlert(error);
        });
    }
  }
};
