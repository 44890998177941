<template>
  <div class="knowledge-base-add-component">
    <div class="knowledge-base-add-component__header">
      <div
        @click="$store.commit('changeCreateMode', false)"
        class="knowledge-base-add-component__to-back"
      >
        <i class="icon-left"></i>
        <span>Назад</span>
      </div>
    </div>
    <Widget type="loader" v-if="loading" />
    <div v-show="!loading" class="knowledge-base-add-component__container">
      <Widget :editValue="articleEditContent" type="text-editor" :unique="'edit-article-editor'" />
      <div class="knowledge-base-add-component__settings">
        <h3 class="knowledge-base-add-component__settings__title">Параметры</h3>
        <Widget
          :style="{ marginBottom: 20 + 'px' }"
          type="input-title"
          placeholder="Название"
          :unique="'article-edit-name'"
          maxlength="100"
          :editValue="articleEditName"
        />
        <Widget
          :entity="'User'"
          :actionEntity="'getItems'"
          :itemName="'full_name'"
          :keyValue="'ArticleAuthorEdit'"
          type="select-new"
          :maxCount="1"
          placeholder="Автор"
          label="Автор"
          :editValue="articleEditAuthor"
        />
        <Widget
          :entity="'User'"
          :actionEntity="'getItems'"
          :itemName="'full_name'"
          :keyValue="'ArticleCo-AuthorEdit'"
          type="select-new"
          :multiple="true"
          :maxCount="3"
          placeholder="Соавтор"
          label="Соавтор"
          :editValue="articleEditCoAuthors"
          :hiddenItems="articleEditAuthor"
        />
        <Widget
          :entity="'Category'"
          :actionEntity="'actionGetItems'"
          :itemName="'name'"
          :keyValue="'ArticleCategoryEdit'"
          type="select-new"
          :multiple="true"
          :maxCount="5"
          placeholder="Категории"
          label="Категории"
          :editValue="articleEditCategories"
          :createMode="true"
        />
        <Widget
          :entity="'Tag'"
          :actionEntity="'actionGetItems'"
          :itemName="'name'"
          :keyValue="'ArticleTagEdit'"
          type="select-new"
          :multiple="true"
          :maxCount="10"
          placeholder="Теги"
          label="Теги"
          :editValue="articleEditTags"
          :createMode="true"
        />

        <div class="knowledge-base-add-component__settings__files">
          <i class="icon-paper-clip"></i>
          <div
            class="knowledge-base-add-component__settings__wrap"
            :style="
              editOldFiles.length || editNewFiles.length || fileUploadingId.length
                ? 'flexDirection: column'
                : ''
            "
          >
            <div class="knowledge-base-add-component__settings__files__header">
              <span
                class="uploader-label"
                v-if="editOldFiles.length || editNewFiles.length || fileUploadingId.length"
                >Файлы</span
              >
              <p
                v-if="editOldFiles.length || editNewFiles.length || fileUploadingId.length"
                class="uploader-clear"
                @click="
                  editOldFiles.splice(0);
                  editNewFiles.splice(0);
                  fileUploadingId.splice(0);
                "
              >
                Очистить
              </p>
            </div>

            <FileEditorRegulation
              :style="
                !editOldFiles.length && !editNewFiles.length && !fileUploadingId.length
                  ? 'marginLeft: 0'
                  : ''
              "
              :attachedFiles="articleData.files"
              acceptMore=".jpg, .jpeg, .png, .pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .mp4, .avi, .3gp, .mpeg, .mov, .mp3, .flv, .wmv"
              @returnAllFiles="returnAllFiles"
              v-bind="{
                multiple: true,
                drop: false,
                dropDirectory: false,
                fileDrop: false,
                lengthText: 7,
                showOnlyFiles: true
              }"
            />
          </div>
        </div>
        <div class="knowledge-base-add-component__settings__links">
          <i class="icon-chain"></i>
          <Widget
            type="links-edit-widget"
            :attachedLinks="articleData.links"
            :unique="'article-edit-links'"
          />
        </div>
        <div class="knowledge-base-add-component__settings__cover">
          <FileUploaderCover
            v-bind="{
              multiple: false,
              drop: true,
              dropDirectory: true,
              fileDrop: true
            }"
            :editValue="cover"
            @clearParentPool="cover = ''"
            @changeCover="changeCover"
          />
        </div>
        <div class="knowledge-base-add-component__settings__buttons">
          <ModalButton
            :type="'cancel'"
            :title="'Отменить'"
            @handleButton="$root.$emit(`button:click_article-edit-cancel`)"
          />
          <ModalButton
            :title="'Сохранить'"
            @handleButton="$root.$emit(`button:click_article-edit-create`)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import uploadFilesMixin from "@/helpers/Files/uploadFiles.mixin";
import uploadCoverMixin from "@/helpers/Files/uploadCover.mixin";

import linksUploaderMixin from "@/components/Widgets/LinksWidget/linksUploader.mixin";

import { domain } from "@/globalVariables";
const Widget = () => import("@/components/Widgets/Widget");
const FileEditorRegulation = () =>
  import("@/components/Widgets/FileUploaderCalendar/FileUploaderEdit");
const Textarea = () => import("@/components/Widgets/Textarea/Textarea");
const FileUploaderCover = () => import("@/views/KnowledgeBase/FileUploaderCover/FileUploaderCover");
import ModalButton from "@/components/Widgets/Button/ModalButton";
import httpClient from "@/api/client/httpClient";

export default {
  name: "ArticleEditComponent",
  mixins: [uploadFilesMixin, uploadCoverMixin, ModalButton],
  props: {
    articleData: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  components: {
    Textarea,
    FileEditorRegulation,
    FileUploaderCover,
    Widget,
    linksUploaderMixin,
    ModalButton
  },
  data() {
    return {
      loading: false,
      articleEditName: "",
      articleEditAuthor: [],
      articleEditCoAuthors: [],
      articleEditCategories: [],
      articleEditTags: [],
      articleEditLinks: [],
      articleEditContent: "",
      articleEditPreview: "",
      editOldFiles: [],
      editNewFiles: [],
      cover: ""
    };
  },
  beforeDestroy() {
    this.$root.$off("button:click_article-edit-cancel");
    this.$root.$off("button:click_article-edit-create");
  },
  created() {
    this.cover = this.articleData.cover;
  },
  mounted() {
    this.articleEditName = this.articleData.name;
    this.articleEditAuthor = [this.articleData.author];
    this.articleEditCoAuthors = this.articleData.co_authors;
    this.articleEditCategories = this.articleData.categories;
    this.articleEditTags = this.articleData.tags;
    this.articleEditLinks = this.articleData.links;
    this.articleEditContent = this.articleData.content;
    this.articleEditPreview = this.articleData.preview;

    this.$root.$on("text-editor:change_edit-article-editor", (value) => {
      this.articleEditContent = value.value;
      this.articleEditPreview = value.preview;
    });
    this.$root.$on("text:change_article-edit-name", (value) => {
      this.articleEditName = value;
    });
    this.$root.$on("changeSelectValue:ArticleAuthorEdit", (value) => {
      this.articleEditAuthor = value.map((item) => item);
    });
    this.$root.$on("changeSelectValue:ArticleCo-AuthorEdit", (value) => {
      this.articleEditCoAuthors = value;
    });
    this.$root.$on("changeSelectValue:ArticleCategoryEdit", (value) => {
      this.articleEditCategories = value;
    });
    this.$root.$on("changeSelectValue:ArticleTagEdit", (value) => {
      this.articleEditTags = value;
    });
    this.$root.$on("links:change_article-edit-links", (value) => {
      this.articleEditLinks = value;
    });
    this.$root.$on("button:click_article-edit-cancel", () => {
      this.$store.commit("changeCreateMode", false);
    });
    this.$root.$on("button:click_article-edit-create", () => {
      this.createChunksPoolFiles();
    });
  },
  methods: {
    returnAllFiles(oldFiles, newFiles) {
      this.editOldFiles = oldFiles;
      this.editNewFiles = newFiles;
      this.fileUploader = this.editNewFiles;
    },
    async createChunksPoolFiles() {
      if (!this.checkValidationData()) return null;
      this.loading = true;
      if (!this.fileUploader.length && !this.coverUploader.length) {
        this.editArticleItem();
        return null;
      } else if (this.fileUploader.length && !this.coverUploader.length) {
        this.createPull(this.fileUploader);
      } else if (!this.fileUploader.length && this.coverUploader.length) {
        this.createPullCover(this.coverUploader);
      } else if (this.fileUploader.length && this.coverUploader.length) {
        this.createPull(this.fileUploader);
      }
    },
    editArticleItem() {
      httpClient({
        url: `/Article/actionUpdate/${this.$route.params.article_id}`,
        method: "PUT",
        data: [
          {
            name: this.articleEditName,
            author_user_id: this.articleEditAuthor.length
              ? this.articleEditAuthor[0].id
              : this.getDataInfoUser.id,
            co_authors: this.articleEditCoAuthors.map((i) => {
              return i.id;
            }),
            categories: this.articleEditCategories.map((i) => {
              return i.id;
            }),
            tags: this.articleEditTags.map((i) => {
              return i.id;
            }),
            links: this.articleEditLinks,
            content: this.articleEditContent,
            preview: this.articleEditPreview,
            attachment_files: this.editOldFiles
              .map((item) => {
                return item.id;
              })
              .concat(this.fileUploadingId),
            cover: this.cover
          }
        ]
      })
        .then(() => {
          this.fileUploadingId = [];
          this.loading = false;
          this.$store.commit("changeCreateMode", false);
          this.$emit("updateArticlePage");
          this.$root.$emit("showNotification", {
            type: "success",
            timeout: 5000,
            label: `Статья успешно изменена!`
          });
        })
        .catch((error) => {
          this.loading = false;
          this.errorAlert(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    filesUploaded() {
      if (!this.coverUploader.length) {
        this.editArticleItem();
      } else {
        this.createPullCover(this.coverUploader);
      }
    },
    filesUploadedCover() {
      this.editArticleItem();
    },
    changeFilesFromUploader(files) {
      this.fileUploader = files;
    },
    checkValidationData() {
      if (!this.articleEditName.trim().length) {
        this.$root.$emit("showNotification", {
          type: "error",
          timeout: 5000,
          label: `Введите название статьи!`
        });
        return false;
      } else if (this.articleEditName.trim().length > 100) {
        this.$root.$emit("showNotification", {
          type: "error",
          timeout: 5000,
          label: `Название памятки не может превышать 100 символов!`
        });
        return false;
      } else if (!this.articleEditAuthor) {
        this.$root.$emit("showNotification", {
          type: "error",
          timeout: 5000,
          label: `Выберите автора!`
        });
        return false;
      } else if (!this.articleEditTags.length) {
        this.$root.$emit("showNotification", {
          type: "error",
          timeout: 5000,
          label: `Должен быть выбран минимум 1 Тег!`
        });
      } else if (this.editOldFiles.concat(this.editNewFiles).length > 10) {
        this.$root.$emit("showNotification", {
          type: "error",
          timeout: 5000,
          label: `Максимальное кол-во файлов 10 !`
        });
        return false;
      } else if (
        !this.articleEditContent.replaceAll("&nbsp;", "").trim() ||
        !this.articleEditContent.replaceAll("<p></p>", "").trim() ||
        !this.articleEditContent.replaceAll("<p><br></p>", "").trim()
      ) {
        this.$root.$emit("showNotification", {
          type: "error",
          timeout: 5000,
          label: `Введите текст статьи!`
        });
        return false;
      } else {
        return true;
      }
    },
    changeCover(cover) {
      this.coverUploader = cover;
    }
  },
  computed: {
    getDataInfoUser() {
      return this.$store.getters.getDataInfoUser;
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/index";
@import "~@/assets/base/color/colors.scss";
@import "~@/assets/base/breakpoints/breakpoints";

.knowledge-base-add-component {
  &--book {
    display: flex;
    justify-content: center;
  }

  &__bg {
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .label {
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    color: $color-text-secondary;
  }

  &__header {
    margin-bottom: 17px;
  }

  &__to-back {
    display: flex;
    align-items: center;
    max-width: max-content;
    user-select: none;
    color: $color-interactive;
    font-size: 13px;
    font-weight: 400;
    line-height: 13px;
    transition: all 0.2s ease;
    cursor: pointer;

    .icon-left {
      margin-right: 5px;
      font-size: 20px;
    }

    &:hover {
      color: $color-button-hover;
    }

    &:active {
      color: $color-button-click;
    }
  }

  .loader-bg {
    margin-left: 50%;
    transform: translateX(-50%);
    margin-top: 15%;
  }

  &__container {
    display: flex;
  }

  &__settings {
    width: 100%;
    height: 85vh;
    max-width: 480px;
    margin-left: 20px;
    padding: 29px 32px 20px 32px;
    overflow: scroll;
    border-radius: 24px;
    background-color: $color-white;

    &--book {
      max-width: 762px;
      height: 80vh;
    }

    &--bookedit {
      max-width: 762px;
      height: fit-content;
    }

    &__title {
      margin: 0 0 20px;
      font-weight: 500;
      color: $color-text-accent;
      font-size: 16px;
      line-height: 20px;
    }

    /deep/ {
      .select-bg {
        margin-bottom: 20px;
        width: 100%;

        .select-content-bg {
          height: 34px;
        }

        .form-group__textarea {
          height: 160px;
        }
      }
    }

    &__files {
      display: flex;
      margin-bottom: 20px;

      .icon-paper-clip {
        margin-right: 11px;
        font-size: 20px;
        color: $color-text-secondary;
      }

      .knowledge-base-add-component__settings__wrap {
        width: 100%;
      }

      &__header {
        display: flex;
        align-items: center;
        margin-bottom: 8px;

        i {
          font-size: 20px;
          color: $icon-subdued;
          margin-right: 8px;
        }

        .uploader-label {
          font-size: 14px;
          line-height: 20px;
          color: $text-subdued;
          font-weight: 400;
        }

        .uploader-clear {
          margin-left: auto;
          color: $text-default;
          padding: 4px;
          font-size: 14px;
          line-height: 20px;
          font-weight: 400;

          cursor: pointer;

          &:hover {
            background: $action-secondary-hovered;
            border-radius: 4px;
          }
        }
      }
    }

    /deep/ {
      .files-background {
        margin-left: 0 !important;
      }
    }

    &__links {
      display: flex;
      margin-bottom: 20px;

      .icon-chain {
        font-size: 20px;
        color: $color-text-secondary;
        margin-right: 8px;
        margin-top: 3px;
      }
    }

    &__buttons {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 50px;

      @media screen and (max-width: $sm) {
        flex-wrap: wrap;
        width: 100%;
        align-items: center;
        margin-top: 20px;
      }

      .button-widget {
        margin-right: 15px;

        @media screen and (max-width: $sm) {
          width: 100%;
          order: 2;
          margin: 0;

          /deep/ {
            button {
              width: 100%;
            }
          }
        }

        &:last-child {
          margin-right: 0;

          @media screen and (max-width: $sm) {
            order: 1;
            margin-bottom: 10px;
          }
        }
      }
    }
  }
  /deep/ {
    .mark-select-bg {
      width: 100%;
      margin-bottom: 20px;

      .select-content-bg {
        height: 34px;

        .select-content {
          background: transparent;
        }
      }
    }
  }

  .tabs {
    margin-bottom: 20px;
  }

  &__job-folder {
    display: flex;
    flex-direction: column;

    .label {
      display: flex;
      align-items: center;
      position: relative;

      .text {
        margin-right: 10px;
      }

      .icon-info {
        cursor: pointer;
        font-size: 12px;

        &:hover + .tooltip-reg {
          display: block;
        }
      }

      .tooltip-reg {
        display: none;
        position: absolute;
        top: -100px;
        left: 0;
        padding: 15px;
        z-index: 1000;

        font-size: 14px;
        line-height: 20px;
        font-weight: 400;
        color: $color-white;

        background-color: $color-interactive;
        border-radius: 10px;
      }
    }
  }

  &__responsibilities {
    display: flex;
    flex-direction: column;

    .label {
      display: flex;
      align-items: center;
      position: relative;

      .text {
        margin-right: 10px;
      }

      .icon-info {
        cursor: pointer;
        font-size: 12px;

        &:hover + .tooltip-reg {
          display: block;
        }
      }

      .tooltip-reg {
        display: none;
        position: absolute;
        top: -80px;
        left: 0;
        padding: 15px;
        z-index: 1000;

        font-size: 14px;
        line-height: 20px;
        font-weight: 400;
        color: $color-white;

        background-color: $color-interactive;
        border-radius: 10px;
      }
    }
  }
}

@media screen and (max-width: $lg) {
  .knowledge-base-add-component {
    &__container {
      flex-flow: column-reverse;
      align-items: center;
    }
    &__settings {
      max-width: 540px;
      height: 100%;
      margin-left: 0;
      margin-bottom: 40px;
    }
  }
}
</style>
